import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import SupportPageTemplate from '../../components/SupportPageTemplate';
import { CommonContext } from '../../context';

export const query = graphql`
  query {
    categories: allWpFaq {
      group(field: faq___category) {
        fieldValue
      }
    }
    allWpFaq {
      nodes {
        id
        content
        title
        slug
        faq {
          category
        }
      }
    }
  }
`;

function SingleSupportQa({ data, pageContext, location }) {
  const qaItem = data.allWpFaq.nodes.find((item) => pageContext.id === item.id);

  const categoryItem = data.categories.group.find(
    (item) =>
      item.fieldValue.toLowerCase() === qaItem.faq.category.toLowerCase(),
  );
  const allQaData = data.allWpFaq.nodes;
  const breadcrumbLinks = [
    { label: 'Support', url: '/support' },
    {
      label: categoryItem.fieldValue,
      url: '/support/' + categoryItem.fieldValue.toLowerCase() + '/',
    },
    { label: qaItem.title },
  ];
  const title = qaItem.title;
  const links = [];
  allQaData.forEach((item) => {
    if (
      item.faq.category.toLowerCase() === categoryItem.fieldValue.toLowerCase()
    ) {
      links.push({
        label: item.title,
        url: `/support/${item.slug}`,
      });
    }
  });
  const additionaliLinkObject = {
    title: 'Related Articles',
    links: links,
  };

  return (
    <CommonContext.Provider value={{ location: location }}>
      <SupportPageTemplate
        title={title}
        additionaliLinkObject={additionaliLinkObject}
        breadcrumbLinks={breadcrumbLinks}
        location={location}
      >
        <div className="markdown">
          <div dangerouslySetInnerHTML={{ __html: qaItem.content }} />
        </div>
      </SupportPageTemplate>
    </CommonContext.Provider>
  );
}

SingleSupportQa.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default SingleSupportQa;
