import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from './layout';
import Breadcrumbs from './Breadcrumbs';

function SupportPageTemplate({
  children,
  breadcrumbLinks,
  title,
  additionaliLinkObject,
  location,
}) {
  const renderCardHeader = () => (
    <div className="flex items-center w-full p-6 md:p-10">
      <Breadcrumbs links={breadcrumbLinks} />
    </div>
  );

  const renderCardBody = () => (
    <div className="p-6 md:p-10">
      <div className="mt-2 mb-8 text-xl md:text-3xl">{title}</div>
      <div className="mb-8">{children}</div>
    </div>
  );

  const renderQuestionCard = () => {
    return (
      <div className="w-full col-span-2 mb-6 text-sm shadow-2xl bg-neutral rounded-xl font-regular md:text-base">
        {renderCardHeader()}
        <div className="w-full h-px bg-neutral-2" />
        {renderCardBody()}
      </div>
    );
  };

  const renderAdditionLinksCard = () => (
    <div>
      <div className="w-full p-6 text-sm shadow-2xl bg-neutral rounded-xl font-regular md:text-base">
        <div className="mb-6 font-semiBold">{additionaliLinkObject.title}</div>
        {additionaliLinkObject.links.map((item) => (
          <div className="my-4 text-accent-2" key={item.label}>
            <Link className="link-underline-accent" to={item.url}>
              {item.label}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <Layout title={title} location={location}>
      <div className="flex justify-center p-6 bg-neutral-1 md:p-12">
        <div className="w-full max-w-6xl md:grid md:grid-cols-3 md:gap-8">
          {renderQuestionCard()}
          {renderAdditionLinksCard()}
        </div>
      </div>
    </Layout>
  );
}

SupportPageTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  breadcrumbLinks: PropTypes.array.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  additionaliLinkObject: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default SupportPageTemplate;
